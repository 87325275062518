import { Component, HostListener, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToggleService } from './toggle.service';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';
import { Admin } from '../../../shared/model/admin.model';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    isSticky: boolean = false;
    admin: Admin | null = null;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    isToggled = false;

    constructor(
        private toggleService: ToggleService,
        private datePipe: DatePipe,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
        this.authenticationService.admin.subscribe(admin => {
            this.admin = admin;
        });
    }

    toggle() {
        this.toggleService.toggle();
    }

    async logout() {
        await this.authenticationService.logout();
        await this.router.navigate(['/login']);
    }

    currentDate: Date = new Date();
    formattedDate: any = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');

}
