import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AppRoutingModule } from '../app-routing.module';
import { FooterComponent } from './layout/footer/footer.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';

@NgModule({

  declarations: [HeaderComponent, SidebarComponent, FooterComponent, NotFoundComponent],
  imports: [
    AppRoutingModule,
    SharedModule
  ],
  exports: [HeaderComponent, SidebarComponent, FooterComponent, NotFoundComponent]
})
export class CoreModule { }
