import { Env } from "./environment.type";

export const environment: Env = {
  production: false,
  apiUrl: "https://api-dev.coordate.com",
  firebase: {
    apiKey: "AIzaSyASAEOOwu-cYkhzLNlCbCxYzFUyTaBk_E0",
    authDomain: "coordate-developement.firebaseapp.com",
    projectId: "coordate-developement",
    storageBucket: "coordate-developement.appspot.com",
    messagingSenderId: "364808874639",
    appId: "1:364808874639:web:b03cddbbd858f07b7b9efa",
    measurementId: "G-Y10G0ZKHHW"
  }
};
