<div class="sidebar-area bg-white box-shadow" [ngClass]="{'active': isToggled}">
    <div class="logo bg-white">
        <a routerLink="/" class="d-flex align-items-center">
            <img src="assets/icon/coordate-logo.png" alt="Coordate">
            <span></span>
        </a>
    </div>
    <div class="burger-menu" (click)="toggle()" [ngClass]="{'active': isToggled}">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar visibility="hover" style="height: 100vh;">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <a routerLink="/" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link">
                        <i class="flaticon-user"></i>

                        Users
                    </a>
                     <a routerLink="/projects" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link">
                        <i class="flaticon-resume"></i>
                        Projects
                    </a>
                    <a routerLink="/keys" routerLinkActive="active"
                        [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link">
                        <i class="flaticon-lock"></i>

                        Keys
                     </a>


                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <i class="flaticon-world"></i>
                                <span class="title">Coming Soon</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <ul class="sidebar-sub-menu">
                            <li class="sidemenu-item">
                                <a routerLink="/dashboard" class="sidemenu-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">
                                
                                <span class="title">Dashboard</span>
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/projects/tasks" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Tasks
                                </a>
                            </li>
                            <li class="sidemenu-item">
                                <a routerLink="/projects/project-create" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                    Project Create
                                </a>
                            </li>
                        </ul>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>