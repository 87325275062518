import { HttpEvent, HttpHandler, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { Auth, idToken } from "@angular/fire/auth";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth: Auth = inject(Auth);
  idToken$ = idToken(this.auth);
  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return this.idToken$.pipe(
      switchMap(token => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`
          }
        });
        return next.handle(req);
      })
    );
  }
}
