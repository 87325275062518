import { Injectable } from "@angular/core";
import { catchError, Observable, throwError } from "rxjs";
import { AdminResponse } from "../model/admin.response";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AppError } from "../../shared/model/app.error";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private static readonly apiUrl = environment.apiUrl;
  public static readonly MODEL = 'admin';
  public static readonly ADMIN_PATH = '/api/admins';
  public static readonly ADMIN_URL = `${AdminService.apiUrl}${AdminService.ADMIN_PATH}`;

  constructor(private http: HttpClient) {
  }

  public getMe(): Observable<AdminResponse> {
    return this.http.get<AdminResponse>(`${AdminService.ADMIN_URL}/me`).pipe(
      catchError(err => {
        return throwError(() => new AppError("admin.error.message", "admin.error.user-not-found", err));
      }),
    );
  }
}
