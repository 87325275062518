import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

export const SHARED_COMPONENTS = [];
export const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatButtonModule,
  MatMenuModule,
  NgScrollbarModule,
  CdkAccordionModule,
  MatExpansionModule,
  MatCardModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatSliderModule,
  MatDialogModule,
  MatSelectModule,
];

@NgModule({
  declarations: [...SHARED_COMPONENTS],
  imports: [
    ...SHARED_MODULES,
  ],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS]
})
export class SharedModule { }
