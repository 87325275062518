export class Admin {
    uuid!: string;
    externalId!: string;
    email!: string;
    contactEmail?: string;
    fullName?: string;
    imageUri?: string;
    phone?: string;
    enabled?: boolean;
    createdAt?: Date;
    updatedAt?: Date;

    constructor({
        fullName,
        contactEmail,
        imageUri,
        phone,
        createdAt,
        updatedAt,
        uuid,
        externalId,
        email
    }: {
        uuid: string,
        externalId: string,
        email: string,
        contactEmail?: string,
        fullName?: string,
        imageUri?: string,
        phone?: string,
        enabled?: boolean,
        createdAt?: Date,
        updatedAt?: Date,
    }) {
        this.fullName = fullName;
        this.contactEmail = contactEmail;
        this.imageUri = imageUri;
        this.phone = phone;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.uuid = uuid;
        this.externalId = externalId;
        this.email = email;
    }
}