import { Injectable, OnDestroy } from '@angular/core';
import { Auth, authState, signInWithEmailAndPassword, signOut, User } from "@angular/fire/auth";
import { catchError, EMPTY, from, map, Observable, Subject, Subscription, switchMap, tap, throwError } from "rxjs";
import { traceUntilFirst } from "@angular/fire/performance";
import { AdminService } from './admin.service';
import { AdminResponse } from '../model/admin.response';
import { FirebaseError } from '@angular/fire/app';
import { AppError } from '../../shared/model/app.error';
import { Admin } from '../../shared/model/admin.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  private readonly userDisposable: Subscription | undefined;
  private adminSubject: Subject<Admin | null> = new Subject();
  private readonly user: Observable<User | null> = EMPTY;
  public readonly admin: Observable<Admin | null> = this.adminSubject.asObservable();
  public isLoggedIn = false;

  constructor(private auth: Auth, private adminService: AdminService) {
    this.user = authState(this.auth);
    this.userDisposable = authState(this.auth).pipe(
      traceUntilFirst('auth'),
      map(u => !!u)
    ).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
      if(isLoggedIn) {
        console.log({isLoggedIn});
        //TODO fix double call when login for first time
        this.adminService.getMe().subscribe((adminResponse: AdminResponse) => {
          this.adminSubject.next(new Admin({ ...adminResponse }));
        });
      }
    });
  }

  login(email: string, password: string): Observable<Admin> {
    return from(signInWithEmailAndPassword(this.auth, email, password))
      .pipe(
        catchError((err: FirebaseError) => {
          return throwError(() => new AppError("auth.error.message", this.mapFirebaseErrorCode(err), err));
        }),
        switchMap(userCredentials => this.adminService.getMe()),
        map((adminResponse: AdminResponse) => new Admin({ ...adminResponse })),
        tap((admin: Admin) => {
          this.adminSubject.next(admin);
        })
      );
  }
  
  async logout() {
    this.adminSubject.next(null);
    return await signOut(this.auth);
  }

  ngOnDestroy() {
    this.adminSubject.complete();
    this.userDisposable?.unsubscribe();
  }

  private mapFirebaseErrorCode(err: FirebaseError): string {
    if (err.code === "auth/user-not-found") {
      return "auth.error.user-not-found";
    }
    if (err.code === "auth/wrong-password") {
      return "auth.error.wrong-password"
    }

    return "auth.error.uknown-error";
  }
}

